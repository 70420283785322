<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.travellingOrdersUpdate')"
            :title="$t('messages.travellingOrdersUpdate')"
            @submitPressed="submitPressed"
        />
        <travelling-form
            v-if="travelOrder"
            :travellingObject="travelOrder"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TravellingForm from "./components/TravellingForm.vue";

export default {
    components: {
        TitleBar,
        TravellingForm,
    },
    data() {
        return {
            travelOrder: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$TravellingOrders.getResource({id}).then((response) => {
                this.travelOrder = response.data;
                this.travelOrder.departure = new Date(response.data.departure)
                this.travelOrder.arrival = new Date(response.data.arrival)
                if ('closedBy' in this.travelOrder) {
                    this.travelOrder.closed = true
                }
                if ('paidBy' in this.travelOrder) {
                    this.travelOrder.paid = true
                }
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
