<template>
    <div class="content-box">
        <div v-if="travel.closedBy" class="alert alert-info">
            <BootstrapIcon icon="info" size="2x"/>
            {{ $t('messages.itemClosed') }}
        </div>
        <b-form @submit.prevent="onSubmit" :class="{ disabled: travel.closedBy }">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        v-if="'id' in travel && !isOwner()"
                        class="row"
                        :label="$t('forms.status')"
                        label-for="h-status"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-status"
                            v-model="statusSelect"
                            :options="statuses"
                        />
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.travel.purpose.$errors.length }"
                        :label="$t('forms.purpose')"
                        label-for="hpurpose"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea
                            v-model="travel.purpose"
                            id="purpose"
                            type="number"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.travel.purpose.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.travel.place.$errors.length }"
                        :label="$t('forms.place')"
                        label-for="hplace"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea v-model="travel.place" id="place" type="number"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.travel.place.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.transport')"
                        label-for="h-transport"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-transport"
                            v-model="transportSelect"
                            :options="transports"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.travel.departure.$errors.length }"
                        :label="$t('forms.departure')"
                        label-for="h-departure"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="departure"
                            v-model="travel.departure"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.travel.departure.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.travel.arrival.$errors.length }"
                        :label="$t('forms.arrival')"
                        label-for="h-arrival"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="arrival"
                            v-model="travel.arrival"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.travel.arrival.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        v-if="'id' in travel && isOwner()"
                        class="row"
                        :label="$t('forms.closedByTp')"
                        label-for="h-closedtp"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-checkbox v-model="travel.closedByTp" id="h-closed" switch/>
                    </b-form-group>
                    <b-form-group
                        v-if="'id' in travel && !isOwner()"
                        class="row"
                        :label="$t('forms.closed')"
                        label-for="h-closed"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-checkbox v-model="travel.closed" id="h-closed" switch/>
                    </b-form-group>
                    <b-form-group
                        v-if="'id' in travel && !isOwner()"
                        class="row"
                        :label="$t('forms.paid')"
                        label-for="h-paid"
                        label-cols-md="2"
                        content-cols-lg="4"

                    >
                        <b-form-checkbox v-model="travel.paid" id="h-paid" switch :disabled="travel.paidBy"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        Datepicker,
        vSelect,
    },
    props: {
        travellingObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(["travelling_order_status", "travelling_order_transport"], this.statuses, this.transports);
        if ("id" in this.travel) {
            this.statusSelect = {
                label: this.$helper.getEnumTranslation("holiday_request_status", this.travel.status, this.$i18n.locale),
                id: this.travel.status,
            };
            this.transportSelect = {
                label: this.$helper.getEnumTranslation("travelling_order_transport", this.travel.transport, this.$i18n.locale),
                id: this.travel.transport,
            };
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            travel: {
                purpose: {required},
                place: {required},
                departure: {required},
                arrival: {required},
            },
        };
    },
    data() {
        return {
            travel: this.travellingObject,
            statuses: [],
            statusSelect: {},
            transports: [],
            transportSelect: {},
            closed: false,
            paid: false,
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.travel.departure) {
                    this.travel.departure = this.$helper.formatDateTimeToSend(this.travel.departure)
                }
                if (this.travel.arrival) {
                    this.travel.arrival = this.$helper.formatDateTimeToSend(this.travel.arrival)
                }
                if (this.action === "add") {
                    this.travel.transport = this.transportSelect.id;
                    this.travel.closedByTp = false;
                    this.create(
                        this.$TravellingOrders,
                        this.travel,
                        this.$t("messages.travellingOrderCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.travel.status = this.statusSelect.id;
                    this.travel.transport = this.transportSelect.id;
                    this.travel.closedBy = null;
                    this.travel.paidBy = null;
                    if (this.travel.closed && !this.travel.closedBy) {
                        this.travel.closedBy = getUserData()["@id"];
                    }
                    if (this.travel.paid && !this.travel.paidBy) {
                        this.travel.paidBy = getUserData()["@id"];
                    }
                    this.$emit("clearAction");
                    this.update(
                        this.$TravellingOrders,
                        this.travel.id,
                        this.travel,
                        this.$t("messages.travellingOrderUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        isOwner() {
            return "employee" in this.travel &&
                getUserData().id === this.travel.employee.id;
        },
        success() {
            this.$router.push(`/travelling-orders`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

